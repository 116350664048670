<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <SinglePopup
    :title="$t('penal-code-upload-popup_component.title')"
    name="penal-code-upload-popup"
    class="default penal-code-upload-popup"
    :adaptive="true"
    height="80%"
    top="50%"
    :active="active"
    @close="close()"
  >
    <div class="penal-code-upload col-pc-12 col-mobile-11 col-margin-auto">
      <div class="upload">
        <div class="title">
          <h2>
            {{ $t('penal-code-upload-popup_component.please-download-template') }}
          </h2>
          <div class="download-link">
            <a href="#">
              <div class="icon"><i class="fas fa-cloud-download-alt"></i></div>
              <div class="text">{{ $t('penal-code-upload-popup_component.download') }}</div>
            </a>
          </div>
        </div>
        <div class="button">
          <button class="primary-full col-pc-4">
            <div class="icon"><i class="fas fa-upload"></i></div>
            <div class="text">{{ $t('penal-code-upload-popup_component.upload-file') }}</div>
          </button>
        </div>
        <div class="disclaimer">
          <p>{{ $t('penal-code-upload-popup_component.keep-in-mind') }}</p>
        </div>
      </div>
      <div class="uploading">
        <div class="title">
          <h2>{{ $t('penal-code-upload-popup_component.please-wait-for-the-upload-process') }}</h2>
          <p>{{ $t('penal-code-upload-popup_component.your-browser-may-freeze') }}</p>
        </div>
        <div class="button">
          <button class="primary-full col-pc-4">
            <div class="icon"><i class="fas fa-spinner"></i></div>
            <div class="text">
              {{ $t('penal-code-upload-popup_component.uploading') }}
            </div>
          </button>
        </div>
        <div class="disclaimer">
          <p class="pourcentage">10%</p>
          <p>{{ $t('penal-code-upload-popup_component.of-upload-done') }}</p>
        </div>
      </div>
      <div class="done">
        <div class="title">
          <h2>{{ $t('penal-code-upload-popup_component.your-upload-is-completed') }}</h2>
          <p>{{ $t('penal-code-upload-popup_component.close-this-popup') }}</p>
        </div>
        <div class="button">
          <button class="success-full col-pc-4">
            <div class="icon"><i class="fas fa-check-circle"></i></div>
            <div class="text">
              {{ $t('penal-code-upload-popup_component.done') }}
            </div>
          </button>
        </div>
        <div class="disclaimer">
          <p class="count">x</p>
          <p>{{ $t('penal-code-upload-popup_component.items-uploaded') }}</p>
        </div>
      </div>
    </div>
  </SinglePopup>
</template>
<style lang="less" scoped src="@/assets/less/components/popup-styles.less"></style>

<script>
import SinglePopup from '@/components/SinglePopup'
import ClickOutside from 'vue-click-outside'

export default {
  name: 'PenalCodeUploadPopup',
  components: {
    SinglePopup
  },
  props: {
    active: Boolean
  },
  data() {
    return {
      isLoading: false
    }
  },
  methods: {
    close: function() {
      this.$emit('close')
    }
  },
  watch: {
    active: {
      handler(value) {
        if (value) {
          this.$modal.show('penal-code-upload-popup')
        } else {
          this.$modal.hide('penal-code-upload-popup')
        }
      }
    }
  },
  mounted() {
    if (this.active) {
      this.$modal.show('penal-code-upload-popup')
    }
  },
  directives: {
    ClickOutside
  }
}
</script>
